import { createStore } from "vuex";
import i18n from "@/i18n";
import StorageService from "@/services/storage.service";
import { ApiService } from "@/services/api.service";
import * as auth from "./modules/auth";
import * as user from "./modules/user";
import * as invoiceAddress from "./modules/invoiceAddress";
import * as balance from "./modules/balance";
import * as profile from "./modules/profile";
import * as subsPackage from "./modules/package";
import * as subscription from "./modules/subscription";
import * as paymentProvider from "./modules/paymentProvider";
import * as payment from "./modules/payment";
import * as statistic from "./modules/statistic";
import * as coupon from "./modules/coupon";
import * as userNotification from "./modules/userNotification";
import router from "@/router/index";

export default createStore({
  state: {
    locale: StorageService.getLocale(),
    theme: StorageService.getTheme(),
    sidebarCollapsed: StorageService.getSidebar(),
    toasts: []
  },
  getters: {
    getToasts: (state) => {
      return state.toasts;
    },
    getTheme: (state) => {
      return state.theme;
    },
    getSidebarCollapsed: (state) => {
      return state.sidebarCollapsed;
    },
    getLocale: (state) => {
      return state.locale;
    }
  },
  mutations: {
    addToast(state, toast) {
      state.toasts.push(toast);
    },
    setTheme(state, theme) {
      state.theme = theme;
      StorageService.saveTheme(theme);
    },
    setSidebarCollapsed(state, isCollapsed) {
      state.sidebarCollapsed = isCollapsed;
      StorageService.saveSidebar(isCollapsed);
    },
    setLocale(state, locale) {
      i18n.global.locale = locale;
      state.locale = locale;
      ApiService.setHeaders(locale);
      StorageService.saveLocale(locale);
    }
  },
  actions: {
    async setLocale({ commit, dispatch }, locale) {
      commit("setLocale", locale);
      await dispatch("user/updateLanguage");
      router.go(0);
    },
    addToast({ commit }, toast) {
      if (!toast.message) toast.message = i18n.global.t("errors.unknown");
      commit("addToast", toast);
    }
  },
  modules: {
    auth,
    user,
    invoiceAddress,
    balance,
    profile,
    subsPackage,
    subscription,
    paymentProvider,
    payment,
    statistic,
    coupon,
    userNotification
  }
});
