import axios from "axios";
import StorageService from "./storage.service";
import store from "../store";
import router from "../router";

class ApiError extends Error {
  constructor(errorStatus, errorTitle) {
    super(errorTitle);
    this.name = this.constructor.name;
    this.errorStatus = errorStatus;
    this.errorTitle = errorTitle;
  }
}

const ApiService = {
  _401Interceptor: null,
  baseAddress: process.env.VUE_APP_USER_API_ADDRESS,

  init() {},

  getHeader() {
    return axios.defaults.headers.common["Authorization"];
  },

  setAuthorizationHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${StorageService.getToken()}`;
  },

  removeAuthorizationHeader() {
    axios.defaults.headers.common["Authorization"] = "";
  },

  setHeaders(locale = null) {
    axios.defaults.headers.common["locale"] = locale ? locale : store.getters.getLocale;
    locale ? (axios.defaults.headers.common["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone) : "";
  },

  removeHeaders() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource, data) {
    return axios.delete(resource, { data: data });
  },

  customRequest(data) {
    return axios(data);
  },

  mount401Interceptor() {
    this._401Interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.request.status == 401) {
          store.dispatch("auth/signOut", false);
          router.push({
            name: "SignIn",
            query: { redirect: router.currentRoute.path }
          });
          throw error;
        }
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    axios.interceptors.response.eject(this._401Interceptor);
  }
};

export { ApiService, ApiError };
