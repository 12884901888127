import InvoiceAddressService from "../../services/invoiceAddress.service";
import i18n from "@/i18n";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getInvoiceAddresses({ dispatch }) {
    try {
      const response = await InvoiceAddressService.getInvoiceAddresses();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async addInvoiceAddress({ dispatch }, payload) {
    try {
      const response = await InvoiceAddressService.addInvoiceAddress(payload);
      return response.id;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async updateInvoiceAddress({ dispatch }, payload) {
    try {
      await InvoiceAddressService.updateInvoiceAddress(payload);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async deleteInvoiceAddress({ dispatch }, id) {
    try {
      await InvoiceAddressService.deleteInvoiceAddress(id);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  }
};
