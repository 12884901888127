<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ content ? `${content} | Captchapp` : `Captchapp` }}
    </template>
  </metainfo>
  <Toasts />
  <router-view />
</template>

<script>
import Toasts from "@/components/Toasts";
import { mapGetters } from "vuex";
export default {
  metaInfo: () => ({
    title: "Captchapp"
  }),
  components: {
    Toasts
  },
  computed: {
    ...mapGetters(["getTheme", "getLocale"])
  },
  created() {
    this.setTheme(this.getTheme);
    this.setLocale(this.getLocale);
  },
  methods: {
    setTheme(theme) {
      var element = document.getElementsByTagName("html");
      element[0].setAttribute("data-bs-theme", theme);
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
    }
  }
};
</script>
