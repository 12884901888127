import { ApiService, ApiError } from "./api.service";

const StatisticService = {
  getGenericStatistic: async function (params) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Statistic/Generic?serviceId=${params.serviceId}&timePeriod=${params.timePeriod}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  getPayAsYouGoSpendStatistic: async function (params) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Statistic/PayAsYouGoSpend?timePeriod=${params.timePeriod}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  getSubscriptionSpendStatistic: async function (params) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Statistic/SubscriptionSpend?timePeriod=${params.timePeriod}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  getUsageStatistic: async function (params) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Statistic/Usage?profileId=${params.profileId}&timePeriod=${params.timePeriod}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  }
};

export default StatisticService;
