import BalanceService from "../../services/balance.service";
import i18n from "@/i18n";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getBalance({ dispatch }) {
    try {
      const response = await BalanceService.getBalance();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  }
};
