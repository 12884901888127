import UserService from "../../services/user.service";
import i18n from "@/i18n";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getUser({ dispatch }) {
    try {
      const response = await UserService.getUser();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async updateUser({ dispatch }, payload) {
    try {
      await UserService.updateUser(payload);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async changePassword({ dispatch }, payload) {
    try {
      await UserService.changePassword(payload);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async updateLanguage({ dispatch }) {
    try {
      await UserService.updateLanguage();
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  }
};
