import StatisticService from "../../services/statistic.service";
import i18n from "@/i18n";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getGenericStatistic({ dispatch }, params) {
    try {
      const response = await StatisticService.getGenericStatistic(params);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async getPayAsYouGoSpendStatistic({ dispatch }, params) {
    try {
      const response = await StatisticService.getPayAsYouGoSpendStatistic(params);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async getSubscriptionSpendStatistic({ dispatch }, params) {
    try {
      const response = await StatisticService.getSubscriptionSpendStatistic(params);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async getUsageStatistic({ dispatch }, params) {
    try {
      const response = await StatisticService.getUsageStatistic(params);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  }
};
