import ProfileService from "../../services/profile.service";
import i18n from "@/i18n";
import StorageService from "@/services/storage.service";

export const namespaced = true;

export const state = {
  currentProfile: StorageService.getCurrentProfile()
};

export const getters = {
  getCurrentProfile: (state) => {
    return state.currentProfile;
  }
};

export const mutations = {
  setCurrentProfile(state, profileId) {
    state.currentProfile = profileId;
    StorageService.saveCurrentProfile(profileId);
  }
};

export const actions = {
  async getProfileById({ dispatch }, id) {
    try {
      const response = await ProfileService.getProfileById(id);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async getProfiles({ dispatch }) {
    try {
      const response = await ProfileService.getProfiles();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async addProfile({ dispatch }, payload) {
    try {
      const response = await ProfileService.addProfile(payload);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async updateProfile({ dispatch }, paylaod) {
    try {
      await ProfileService.updateProfile(paylaod);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async renewApiKey({ dispatch }, id) {
    try {
      const response = await ProfileService.renewApiKey(id);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async deleteProfile({ dispatch }, id) {
    try {
      await ProfileService.deleteProfile(id);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  }
};
