import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createMetaManager, plugin as vueMetaPlugin } from "vue-meta";
import "./assets/style.scss";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import i18n from "./i18n";
import StorageService from "./services/storage.service";
import { ApiService } from "./services/api.service";

ApiService.setHeaders();

if (StorageService.getToken && StorageService.getUser) {
  ApiService.setAuthorizationHeader();
  ApiService.mount401Interceptor();
}

createApp(App).use(i18n).use(store).use(router).use(vueMetaPlugin).use(createMetaManager()).mount("#app");
