import { ApiService, ApiError } from "./api.service";

const PaymentService = {
  getPaymentHistory: async function () {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Payment`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  initializePayment: async function (payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Payment/Initialize`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  }
};

export default PaymentService;
