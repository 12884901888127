import { ApiService, ApiError } from "./api.service";

const InvoiceAddressService = {
  getInvoiceAddresses: async function () {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}InvoiceAddress`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  addInvoiceAddress: async function (payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}InvoiceAddress`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  updateInvoiceAddress: async function (payload) {
    try {
      const response = await ApiService.put(`${ApiService.baseAddress}InvoiceAddress`, payload);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  deleteInvoiceAddress: async function (id) {
    try {
      const response = await ApiService.delete(`${ApiService.baseAddress}InvoiceAddress/${id}`);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  }
};

export default InvoiceAddressService;
