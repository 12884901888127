import { ApiService, ApiError } from "./api.service";

const SubscriptionService = {
  getSubscriptions: async function () {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Subscription`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  addSubscription: async function (payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Subscription`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  }
};

export default SubscriptionService;
