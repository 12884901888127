import { ApiService, ApiError } from "./api.service";

const ProfileService = {
  getProfileById: async function (id) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Profile/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  getProfiles: async function () {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Profile`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  addProfile: async function () {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Profile`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  updateProfile: async function (payload) {
    try {
      const response = await ApiService.put(`${ApiService.baseAddress}Profile`, payload);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  renewApiKey: async function (id) {
    try {
      const response = await ApiService.put(`${ApiService.baseAddress}Profile/RenewApiKey/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  deleteProfile: async function (id) {
    try {
      const response = await ApiService.delete(`${ApiService.baseAddress}Profile/${id}`);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  }
};

export default ProfileService;
