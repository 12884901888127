import { ApiService, ApiError } from "./api.service";

const BalanceService = {
  getBalance: async function () {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Balance`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  }
};

export default BalanceService;
