import { ApiService, ApiError } from "./api.service";

const UserNotificationService = {
  getUserNotifications: async function (includeMessages) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}UserNotification?includeMessages=${includeMessages}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  getUserNotificationById: async function (id) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}UserNotification/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  setUserNotificationAsRead: async function (payload) {
    try {
      const response = await ApiService.put(`${ApiService.baseAddress}UserNotification`, payload);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  }
};

export default UserNotificationService;
