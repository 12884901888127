import { ApiService, ApiError } from "./api.service";

const PaymentProviderService = {
  getPaymentProviders: async function () {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}PaymentProvider`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  }
};

export default PaymentProviderService;
