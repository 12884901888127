import AuthService from "../../services/auth.service";
import StorageService from "../../services/storage.service";
import router from "../../router";
import i18n from "@/i18n";
export const namespaced = true;

export const state = {
  token: StorageService.getToken(),
  user: StorageService.getUser()
};

export const getters = {
  signedIn: (state) => {
    return state.token && state.user ? true : false;
  },
  user: (state) => {
    return state.user;
  }
};

export const mutations = {
  signInSuccess(state, userData) {
    state.token = userData.token;
    state.user = userData.emailAddress;
    router.push(router.currentRoute.value.query.redirect || { name: "Dashboard" });
  },

  signOutSuccess(state) {
    state.token = "";
    state.user = "";
    router.push({ name: "SignIn" });
  }
};

export const actions = {
  async signIn({ commit, dispatch }, credentials) {
    try {
      const response = await AuthService.signIn(credentials);
      commit("signInSuccess", response);
      return response;
    } catch (error) {
      if (error.errorTitle != "EMAIL_NOT_VERIFIED_EXCEPTION") {
        dispatch(
          "addToast",
          {
            type: "error",
            message: i18n.global.t(`errors.${error.errorTitle}`)
          },
          { root: true }
        );
      }
      throw error.errorTitle;
    }
  },

  async signUp({ dispatch }, credentials) {
    try {
      await AuthService.signUp(credentials);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async verifyEmail({ dispatch }, token) {
    try {
      await AuthService.verifyEmail(token);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async resendEmailVerification({ dispatch }, payload) {
    try {
      await AuthService.resendEmailVerification(payload);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async sendResetPasswordLink({ dispatch }, payload) {
    try {
      await AuthService.sendResetPasswordLink(payload);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async resetPassword({ dispatch }, payload) {
    try {
      await AuthService.resetPassword(payload);
      return true;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async signOut({ commit }) {
    AuthService.signOut();
    commit("signOutSuccess");
  }
};
