import { ApiService, ApiError } from "./api.service";

const UserService = {
  getUser: async function () {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}User`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  updateUser: async function (payload) {
    try {
      const response = await ApiService.put(`${ApiService.baseAddress}User`, payload);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  changePassword: async function (payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}User/ChangePassword`, payload);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  updateLanguage: async function () {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}User/UpdateLanguage`, {});
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  }
};

export default UserService;
