import { ApiService, ApiError } from "./api.service";
import StorageService from "./storage.service";
import store from "../store";

const AuthService = {
  signIn: async function (credentials) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Auth/SignIn`, credentials);
      StorageService.saveUser(response.data.emailAddress);
      StorageService.saveToken(response.data.token);
      ApiService.setAuthorizationHeader();
      ApiService.mount401Interceptor();
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  signUp: async function (credentials) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Auth/SignUp`, credentials);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  verifyEmail: async function (token) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Auth/VerifyEmail`, token);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  resendEmailVerification: async function (payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Auth/ResendEmailVerification`, payload);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  sendResetPasswordLink: async function (payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Auth/SendResetPasswordLink`, payload);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  resetPassword: async function (payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Auth/ResetPassword`, payload);
      return response;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  signOut() {
    StorageService.removeUser(store.state.domain);
    StorageService.removeToken(store.state.domain);
    ApiService.removeAuthorizationHeader();
    ApiService.unmount401Interceptor();
  }
};

export default AuthService;
