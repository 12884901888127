const USER = "user";
const TOKEN = "token";
const THEME = "theme";
const SIDEBAR = "sidebarCollapsed";
const LOCALE = "locale";
const PROFILE = "profile";

function getNavigatorLanguage() {
  return navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || "en";
}

const StorageService = {
  getUser() {
    if (JSON.parse(localStorage.getItem(USER))) return JSON.parse(localStorage.getItem(USER));
    else return null;
  },
  getToken() {
    if (JSON.parse(localStorage.getItem(TOKEN))) return JSON.parse(localStorage.getItem(TOKEN));
    else return null;
  },
  saveUser(user) {
    localStorage.setItem(USER, JSON.stringify(user));
  },
  saveToken(token) {
    localStorage.setItem(TOKEN, JSON.stringify(token));
  },
  removeUser() {
    localStorage.removeItem(USER);
  },
  removeToken() {
    localStorage.removeItem(TOKEN);
  },
  getTheme() {
    const getPreferredScheme = () => (window?.matchMedia?.("(prefers-color-scheme:dark)")?.matches ? "dark" : "light");
    try {
      if (JSON.parse(localStorage.getItem(THEME))) {
        if (JSON.parse(localStorage.getItem(THEME)) == "light" || JSON.parse(localStorage.getItem(THEME)) == "dark") {
          return JSON.parse(localStorage.getItem(THEME));
        } else return getPreferredScheme();
      } else return getPreferredScheme();
    } catch (error) {
      return getPreferredScheme();
    }
  },
  saveTheme(theme) {
    localStorage.setItem(THEME, JSON.stringify(theme));
  },
  getSidebar() {
    try {
      if (JSON.parse(localStorage.getItem("sidebarCollapsed"))) {
        if (JSON.parse(localStorage.getItem("sidebarCollapsed")) === true) return true;
        else return false;
      } else return false;
    } catch (error) {
      return false;
    }
  },
  saveSidebar(isCollapsed) {
    localStorage.setItem(SIDEBAR, isCollapsed);
  },
  getLocale() {
    let navigatorLocale = getNavigatorLanguage();
    navigatorLocale = navigatorLocale.includes("-") ? navigatorLocale.split("-")[0] : navigatorLocale;
    try {
      if (JSON.parse(localStorage.getItem("locale"))) {
        return JSON.parse(localStorage.getItem("locale"));
      } else return navigatorLocale;
    } catch (error) {
      return navigatorLocale;
    }
  },
  saveLocale(locale) {
    localStorage.setItem(LOCALE, JSON.stringify(locale));
  },
  getCurrentProfile() {
    try {
      if (JSON.parse(localStorage.getItem(PROFILE))) return JSON.parse(localStorage.getItem(PROFILE));
      else return null;
    } catch (error) {
      return null;
    }
  },
  saveCurrentProfile(profileId) {
    localStorage.setItem(PROFILE, JSON.stringify(profileId));
  }
};

export default StorageService;
