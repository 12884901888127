import { ApiService, ApiError } from "./api.service";

const CouponService = {
  getCoupons: async function () {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Coupon`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  },

  checkCoupon: async function (payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Coupon/Check`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.status, error.response.data.title);
    }
  }
};

export default CouponService;
