import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
const routes = [
  {
    path: "/",
    children: [
      {
        path: "/",
        component: () => import("@/layouts/DashboardLayout.vue"),
        children: [
          {
            path: "",
            name: "Dashboard",
            component: () => import("@/views/Dashboard/Dashboard.vue")
          },
          {
            path: "subscriptions",
            name: "Subscriptions",
            component: () => import("@/views/Dashboard/Subscriptions.vue")
          },
          {
            path: "balance",
            name: "Balance",
            component: () => import("@/views/Dashboard/Balance.vue")
          },
          {
            path: "market",
            name: "Market",
            component: () => import("@/views/Dashboard/Market.vue")
          },
          {
            path: "usages",
            name: "Usages",
            component: () => import("@/views/Dashboard/Usages.vue")
          },
          {
            path: "my-account",
            name: "MyAccount",
            component: () => import("@/views/Dashboard/MyAccount.vue")
          },
          {
            path: "notifications",
            name: "UserNotifications",
            component: () => import("@/views/Dashboard/UserNotifications.vue")
          }
        ]
      },
      {
        path: "/",
        redirect: `/sign-in`,
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        },
        component: () => import("@/layouts/AuthLayout.vue"),
        children: [
          {
            path: "sign-in",
            name: "SignIn",
            component: () => import("@/views/Auth/SignIn.vue")
          },
          {
            path: "sign-up",
            name: "SignUp",
            component: () => import("@/views/Auth/SignUp.vue")
          },
          {
            path: "forgot-password",
            name: "ForgotPassword",
            component: () => import("@/views/Auth/ForgotPassword.vue")
          },
          {
            path: "reset-password",
            name: "ResetPassword",
            component: () => import("@/views/Auth/ResetPassword.vue"),
            beforeEnter(to, from, next) {
              if (to.query.token) next();
              else next({ path: "/404" });
            }
          },
          {
            path: "verify-email",
            name: "EmailVerification",
            component: () => import("@/views/Auth/EmailVerification.vue"),
            async beforeEnter(to, from, next) {
              if (to.query.token) {
                const response = await store.dispatch("auth/verifyEmail", {
                  token: to.query.token
                });
                if (!response) next({ name: "SignIn" });
                else next();
              } else next({ path: "/404" });
            }
          }
        ]
      },
      {
        path: "/:notFound",
        meta: {
          public: true
        },
        component: () => import("@/views/Error/NotFound")
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  let signedIn = store.getters["auth/signedIn"];
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);

  if (!isPublic && !signedIn) {
    next({
      path: `/sign-in`,
      query: { redirect: to.fullPath }
    });
  } else if (signedIn && onlyWhenLoggedOut) {
    next(`/`);
  } else {
    next();
  }
});

router.afterEach(() => {
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 1);
});

export default router;
