import UserNotificationService from "../../services/userNotification.service";
import i18n from "@/i18n";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getUserNotifications({ dispatch }, payload) {
    try {
      const response = await UserNotificationService.getUserNotifications(payload);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async getUserNotificationById({ dispatch }, id) {
    try {
      const response = await UserNotificationService.getUserNotificationById(id);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  },

  async setUserNotificationAsRead({ dispatch }, payload) {
    try {
      const response = await UserNotificationService.setUserNotificationAsRead(payload);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: i18n.global.t(`errors.${error.errorTitle}`)
        },
        { root: true }
      );
    }
  }
};
